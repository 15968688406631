



















import { Component, Mixins } from "vue-property-decorator";
import HelperDinamicallyForm from "@/components/DinamicallyForm/HelperDinamicallyForm";
import ticketModule from "@/store/modules/ticket-module";
import { ticket } from "@/shared/dtos/ticket";
import usuarioModule from "@/store/modules/usuario-module";
import enumeracionModule from "@/store/modules/enumeracion-module";
import { enumeracion } from "@/shared/dtos/enumeracion";

@Component({
  components: {},
})
export default class TicketsFormulario extends Mixins(HelperDinamicallyForm) {
  public item_pantalla: ticket = new ticket();
  public categoria: enumeracion[] = [];
  public prioridades: enumeracion[] = [];
  public estado: enumeracion[] = [];

  public async OnCreated() {
    await usuarioModule.getusuarios();
    await enumeracionModule.getenumeraciones_fromtipo(4).then((x) => {
      this.prioridades = x.enumeraciones;
    });
    await enumeracionModule.getenumeraciones_fromtipo(5).then((x) => {
      this.categoria = x.enumeraciones;
    });
    await enumeracionModule.getenumeraciones_fromtipo(6).then((x) => {
      this.estado = x.enumeraciones;
    });

    if (this.getparamId().HayParametro) {
      await ticketModule.getticket(this.getparamId().Parametro);
      this.item_pantalla = ticketModule.ticket;
    }
    //Para la clonacion automatica
    this.BasicElement = this.item_pantalla;
  }

  public CreateFields() {
    //Empezamos a crear una pantalla
    //configuracion de la clonacion automatica

    this.AddFormItemDataDefaultString(
      this.item_pantalla.nombre,
      "Nombre",
      "nombre",
      60
    )
      .isRequired()
      .Dimesiones(12, 6, 4);
    this.AddFormItemDataDefaultDate(
      new Date(this.item_pantalla.fecha_inicio),
      "Fecha inicio",
      "fecha_inicio"
    ).isRequired();

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_responsable,
      "Responsable",
      "id_responsable"
    )
      .Dimesiones(12, 6, 4)
      .CreateComboSingle(usuarioModule.usuarios, "nombre");

    this.AddFormItemDataDefaultString(
      this.item_pantalla.descripcion,
      "Descripción",
      "descripcion"
    )
      .isRequired()
      .CreateTextArea();

    this.AddFormItemDataDefaultDate(
      new Date(this.item_pantalla.fecha_fin),
      "Última actualización del caso",
      "fecha_fin"
    )
      .Dimesiones(12, 6, 3)
      .isReadOnly()
      .isVisible(new Date(this.item_pantalla.fecha_fin).getFullYear() > 0);

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_prioridad,
      "Prioridad",
      "id_prioridad"
    )
      .Dimesiones(12, 6, 3)
      .CreateComboSingle(this.prioridades, "nombre");

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_categoria,
      "Categoría",
      "id_categoria"
    )
      .Dimesiones(12, 6, 3)
      .CreateComboSingle(this.categoria, "nombre");
    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_estado,
      "Estado",
      "id_estado"
    )
      .Dimesiones(12, 6, 3)
      .CreateComboSingle(this.estado, "nombre");
    this.AddFormItemDataDefaultString(
      this.item_pantalla.resolucion,
      "Resolución",
      "resolucion"
    ).CreateTextArea();
  }

  public Insertar(object: ticket) {
    object.fecha_fin = new Date();
    ticketModule.guardarticket(object).then(() => {
      this.AllSaveOk();
    });
  }

  public Actualizar(object: ticket) {
    object.fecha_fin = new Date();
    ticketModule.modificarticket(object).then(() => {
      this.AllSaveOk();
    });
  }
}
